<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('User') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'role-list' }" >
          {{ $t('Role') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create Role') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'role-list' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- base info -->
    <validation-observer ref="infoRules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Role') }}</b-card-title>
        <div class="ll-required">* {{ $t('Required Fields') }}</div>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4' v-if="false">
            <b-form-group
              class="ll-bform"
              label="Organization"
              label-for="org"
            >
            <validation-provider
              #default="{ errors }"
              name="org"
              rules="required"
            >
              <v-select
                id="org"
                :options="orgList"
                label="name"
                @input="getOrgId"
                v-model="org"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="roleName"
            >
            <validation-provider
              #default="{ errors }"
              name="roleName"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Role Name') }}</label>
              <b-form-input
                id="roleName"
                v-model="roleName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Role Description')"
              label-for="roleDes"
            >
            <!-- <validation-provider
              #default="{ errors }"
              name="roleDes"
              rules="required"
            > -->
              <b-form-input
                id="roleDes"
                v-model="roleInfo.description"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    </validation-observer>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Permission') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <vxe-table
          resizable
          row-id="id"
          :data="permissList"
          :tree-config="{children: 'children'}"
          :checkbox-config="{labelField: 'name'}"
          @checkbox-change="selectChangeEvent"
          @checkbox-all="allSelect"
          empty-text="No matching records found">
          <vxe-table-column type="checkbox" width="400" tree-node></vxe-table-column>
          <!-- <vxe-table-column field="id" title="name"></vxe-table-column> -->
          <vxe-table-column field="routerPath" :title="$t('RouterPath')"></vxe-table-column>
          <vxe-table-column field="type" :title="$t('Type')"></vxe-table-column>
          <vxe-table-column field="description" :title="$t('Description')"></vxe-table-column>
          <!-- <vxe-table-column field="view" title="View">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.view"
                v-model="row.view"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column field="edit" title="Edit">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.edit"
                v-model="row.edit"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column field="export" title="Export">
           <template #default="{ row }">
              <b-form-checkbox
                :checked="row.export"
                v-model="row.export"
              />
            </template>
          </vxe-table-column> -->
        </vxe-table>
      </b-card-body>
    </b-card>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    BFormCheckbox,
    BImg,
  },
  data() {
    return {
      required,
      roleInfo: {
        name: '',
        orgId: '',
        description: '',
        displayName: null,
        permissionIds: [],
      },
      roleName: '',
      role: '',
      orgList: [],
      org: '',
      roleId: '',
      pCondition: {
        create: null,
        read: null,
        update: null,
        delete: null,
        diyModuleName: '',
        routerPathString: '',
        name: '',
        id: '',
      },
      ssd: {},
      permissList: [],
    }
  },
  computed: {
  },
  created() {},
  mounted() {
    this.getOrganization()
    this.queryPerm()
    // this.createPremiss()
  },
  methods: {
    selectChangeEvent ({ records }) {
      // console.info(`勾选${records.length}个树形节点`, records)
      const arr = []
      const arr1 = []
      records.map(i => {
        arr.push(i.id)
        arr1.push(i.parentId)
      })
      // console.log(arr)
      const arr2 = arr.concat(arr1)
      this.roleInfo.permissionIds = [...new Set(arr2)]
    },
    allSelect({ records }) {
      const arr = []
      records.map(i => {
        arr.push(i.id)
      })
      this.roleInfo.permissionIds = arr
    },
    // 初始化权限列表
    queryPerm() {
      this.$http.get('admin/settings/permission/getList').then(res => {
        this.permissList = res.data.data
      })
    },
    onView(s, i) {
      this.ssd.name = s.name
      this.ssd.routerPath = s.routerPath
      this.ssd.view = s.view
      this.ssd.edit = s.edit
      this.ssd.export = s.export
      // console.log(this.ssd)

    },
    onEdit(s) {
      this.ssd.name = s.name
      this.ssd.routerPath = s.routerPath
      this.ssd.view = s.view
      this.ssd.edit = s.edit
      this.ssd.export = s.export
      // console.log(s.edit)
    },
    onExport(s) {
      this.ssd.name = s.name
      this.ssd.routerPath = s.routerPath
      this.ssd.view = s.view
      this.ssd.edit = s.edit
      this.ssd.export = s.export
      // console.log(s.export)
    },
    // 查询组织信息
    getOrganization() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.orgList = res.data.data
      })
    },
    // 获取组织ID
    getOrgId(id) {
      this.roleInfo.orgId = id.id
      this.org = id.name
    },
    // add role
    createRole() {
      this.roleInfo.displayName = this.roleName.trim()
      this.roleInfo.name = this.roleName.trim()
      this.$http.post('/admin/settings/role/createRole', this.roleInfo).then(res => {
        // console.log(res.data)
        if (res.data.code === '200') {
          // this.roleId = res.data.data.id
          // this.createPremiss()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'role-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    // 创建权限
    createPremiss() {
      const arr = []
      // console.log(this.$refs.table.bodyData)
      let result = this.$refs.table.bodyData
    //  let result = [ {
    //         "name": "COD FOD List",
    //         "routerPath": "cod-fod-list",
    //         "icon": "MessageSquareIcon",
    //         "type" : "MENU",
    //         "parentId" : 0,
    //         "seqNo" : 111
    //     }]
      result.map( o => {
        let obj = {}
        obj.name = o.name
        obj.routerPath = o.routerPath
        obj.view = o.view
        obj.edit = o.edit
        obj.export = o.export
        obj.type = o.type
        obj.seqNo = o.seqNo
        arr.push(obj)
      })
      // console.log(arr)
      const prm = {}
      // prm.roleId = this.roleId
      prm.permissions = arr
      // console.log(prm)
      this.$http.post('admin/settings/permission/createPermission', prm).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'role-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    // 创建前验证
    validationForm() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.createRole()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>
<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
